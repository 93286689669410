export default [
    { id: 0, name: '' },
    { id: 1, name: 'van' },
    { id: 2, name: 'de' },
    { id: 3, name: 'van der' },
    { id: 4, name: 'van de' },
    { id: 5, name: 'van den' },
    { id: 6, name: 'den' },
    { id: 7, name: 'ten' },
    { id: 8, name: 'ter' },
    { id: 9, name: 'te' },
    { id: 10, name: "van 't" },
    { id: 11, name: 'el' },
    { id: 12, name: 'le' },
    { id: 13, name: 'van het' },
    { id: 14, name: "in 't" },
    { id: 15, name: "'t" },
    { id: 16, name: 'von' },
    { id: 17, name: 'du' },
    { id: 18, name: 'da' },
    { id: 19, name: 'de la' },
    { id: 20, name: 'la' },
    { id: 21, name: 'der' },
    { id: 22, name: 'van ter' },
    { id: 23, name: "op 't" },
    { id: 24, name: 'op den' },
    { id: 25, name: 'op ten' },
    { id: 26, name: 'op de' },
    { id: 27, name: 'uit den' },
    { id: 28, name: 'di' },
    { id: 29, name: 'voor den' },
    { id: 30, name: 'op der' },
    { id: 31, name: 'in den' },
];
