export default [
    { id: 0, name: '' },
    { id: 1, name: 'Dhr' },
    { id: 2, name: 'Mevr' },
    { id: 3, name: 'De heer, Mevrouw' },
    { id: 4, name: 'Familie' },
    { id: 5, name: 'De heer of mevrouw' },
    { id: 6, name: 'De heren' },
    { id: 7, name: 'De dames' },
    { id: 8, name: 'Erven' },
];
