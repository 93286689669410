import React from 'react';

function RedirectionProjectManager() {
    return (
        <>
            <p>Voor projectleider verwachten we geen doorverwijzing kansactie</p>
        </>
    );
}

export default RedirectionProjectManager;
