import React from 'react';

function VisitExternalParty() {
    return (
        <>
            <p>Voor externe partij verwachten we geen bezoek kansactie</p>
        </>
    );
}

export default VisitExternalParty;
