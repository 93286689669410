export default [
    { id: 'AU', name: 'Australië' },
    { id: 'BE', name: 'België' },
    { id: 'CA', name: 'Canade' },
    { id: 'DK', name: 'Denemarken' },
    { id: 'DE', name: 'Duitsland' },
    { id: 'FI', name: 'Finland' },
    { id: 'FR', name: 'Frankrijk' },
    { id: 'IE', name: 'Ierland' },
    { id: 'IT', name: 'Italië' },
    { id: 'LU', name: 'Luxemburg' },
    { id: 'NL', name: 'Nederland' },
    { id: 'NZ', name: 'Nieuw-Zeeland' },
    { id: 'NO', name: 'Noorwegen' },
    { id: 'AT', name: 'Oostenrijk' },
    { id: 'PL', name: 'Polen' },
    { id: 'PT', name: 'Portugal' },
    { id: 'ES', name: 'Spanje' },
    { id: 'CZ', name: 'Tsjechië' },
    { id: 'GB', name: 'Verenigd Koninkrijk' },
    { id: 'VS', name: 'Verenigde Staten' },
    { id: 'SE', name: 'Zweden' },
    { id: 'CH', name: 'Zwitserland' },
];
