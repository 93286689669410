import React from 'react';

function QuotationRequestExternalParty() {
    return (
        <>
            <p>Voor externe partij verwachten we geen offerteverzoek kansactie</p>
        </>
    );
}

export default QuotationRequestExternalParty;
